import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSimpleAlert from 'vue-simple-alert'
import '@/config/moment.js'
import '@/assets/css/tailwind.css'
import Meta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueSimpleAlert)
Vue.use(Meta)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
