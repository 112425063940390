import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            breadCrumb: 'Beranda',
        },
        // beforeEnter: (to, from, next) => {
        //     next('/maintenance')
        // },
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import('@/views/FAQ.vue'),
    },

    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/views/UnderConstruction.vue'),
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import('@/views/Blog.vue'),
    },
    {
        path: '/tentang',
        name: 'tentang',
        component: () => import('@/views/About.vue'),
    },
    {
        path: '/anggota',
        name: 'anggota',
        component: () => import('@/views/BeAMember.vue'),
        meta: {
            breadcrumb: 'Jadi Bagian GenBI IPB',
        },
        // children: [
        //     {
        //         path: ':unggulan',
        //         component: () => import('@/views/S1Unggulan.vue'),
        //         meta: {
        //             breadcrumb: 'Beasiswa S1 Unggulan',
        //         },
        //         props: true,
        //     },
        //     {
        //         path: ':reguler',
        //         component: () => import('@/views/S1Reguler.vue'),
        //         meta: {
        //             breadcrumb: 'Beasiswa S1 Reguler',
        //         },
        //         props: true,
        //     },
        //     {
        //         path: ':diploma',
        //         component: () => import('@/views/Diploma.vue'),
        //         meta: {
        //             breadcrumb: 'Beasiswa Diploma',
        //         },
        //         props: true,
        //     },
        // ],
    },
    {
        path: '/unggulan',
        name: 'unggulan',
        component: () => import('@/views/S1Unggulan.vue'),
    },
    {
        path: '/partner',
        name: 'partner',
        component: () => import('@/views/Partner.vue'),
    },
    {
        path: '/reguler',
        name: 'reguler',
        component: () => import('@/views/S1Reguler.vue'),
    },
    {
        path: '/diploma',
        name: 'diploma',
        component: () => import('@/views/Diploma.vue'),
    },
    {
        path: '/kontak',
        name: 'kontak',
        component: () => import('@/views/Kontak.vue'),
    },
    {
        path: '*',
        name: 'empty',
        redirect: 'maintenance',
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
