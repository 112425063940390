<template>
    <div id="app">
        <app-navbar />
        <hr />
        <div class="container mx-auto px-3">
            <transition slot="content" name="component-fade-y" mode="out-in">
                <router-view />
            </transition>
        </div>
        <app-footer class="px-8 pb-8" />
    </div>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default {
    name: 'App',
    components: {
        'app-navbar': Navbar,
        'app-footer': Footer,
    },
    metaInfo: {
        title: 'GenBI IPB',
        htmlAttrs: {
            lang: 'id',
            amp: true,
        },
    },
}
</script>

<style lang="less">
.component-fade-y-enter-active,
.component-fade-y-leave-active {
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.component-fade-x-enter-active,
.component-fade-x-leave-active {
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.component-fade-enter-active,
.component-fade-leave-active {
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.component-fade-enter,
.component-fade-leave-to {
    opacity: 0;
}
.component-fade-x-enter,
.component-fade-x-leave-to {
    opacity: 0;
    transform: translateX(50px);
}
.component-fade-y-enter,
.component-fade-y-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

body {
    font-family: 'Lato', sans-serif;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#navbar {
    padding: 30px;
}

#navbar a {
    font-weight: bold;
    color: #2c3e50;
}

#navbar a.router-link-exact-active {
    color: #42b983;
}
</style>
