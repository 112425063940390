<template>
    <div>
        <div
            id="home-hero"
            class="my-5 rounded overflow-hidden shadow-lg py-20 hero"
            style="
            "
        >
            <!-- TODO: Fix this alignment -->
            <div class="px-6 pb-4 pt-28">
                <div class="font-bold text-4xl mb-2 text-white row-start-6">
                    Siap menjadi GenBIers IPB selanjutnya?
                </div>
            </div>
            <div class="px-6 pt-4 pb-2">
                <button
                    @click="$router.push('/anggota')"
                    class="rounded-full px-5 py-2 bg-gray-200 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 font-bold"
                    :style="{ color: '#0D528D' }"
                >
                    Ya, saya siap!
                </button>
            </div>
        </div>
        <div class="py-10 my-5 flex">
            <div class="flex-none lg:w-32"></div>
            <div class="flex-grow">
                <div class="px-6">
                    <h1
                        class="font-bold text-3xl mb-2"
                        :style="{ color: '#0D528D' }"
                    >
                        Tentang GenBI IPB
                    </h1>
                </div>
                <p class="mt-8 px-12 leading-8">
                    GenBI atau Generasi Baru Indonesia merupakan komunitas yang
                    diinisiasi oleh penerima Beasiswa Bank Indonesia. GenBI
                    terdapat di seluruh nusantara, salah satu diantaranya adalah
                    GenBI Institut Pertanian Bogor. GenBI IPB memiliki tiga
                    proyek utama yang bergerak di bidang pendidikan, lingkungan,
                    dan kesehatan
                </p>
                <button
                    class="mt-8 rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-10 text-white"
                    @click="$router.push('/tentang')"
                >
                    Selengkapnya
                </button>
            </div>
            <div class="flex-none lg:w-32"></div>
        </div>
        <div
            class="py-10 px-5 my-5 grid lg:grid-cols-2 md:grid-cols-1 gap-10 items-center"
        >
            <div class="py-5" style="display: flex; justify-content: flex-end">
                <img
                    src="        https://lh3.googleusercontent.com/A6W1FMuOxpNs3hakmu2y7IctzIdBSxPm7Wiu1EIqRMUG87VPTVtV81omUkJwJtF5l3zb2wPOV4Pfn33vRlGP_qSwB0xtrlf-4hDGEIcpBH5YLyzM6fxFZPmuK9eCgdtCpzmYsBNtew=w2400"
                />
            </div>
            <div class="py-5 lg:text-left lg:order-first">
                <h2
                    class="font-bold text-3xl mb-2"
                    :style="{ color: '#0D528D' }"
                >
                    Jadi bagian GenBI IPB
                </h2>
                <p class="mb-3 mt-8 leading-8">
                    GenBI IPB selalu mencari seseorang yang selalu bersinergi
                    dan antusias dalam memberi manfaat kepada orang lain. Jika
                    kamu orang tersebut, kami sangat senang kamu ingin bergabung
                    bersama kami!
                </p>
                <button
                    class="mt-8 rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white"
                    @click="$router.push('/anggota')"
                >
                    Selengkapnya
                </button>
            </div>
        </div>
        <div
            class="py-10 px-5 my-5 grid lg:grid-cols-2 md:grid-cols-1 gap-10 items-center"
        >
            <div class="py-5">
                <img
                    src="https://lh3.googleusercontent.com/2emTJfaXLonpwFL94zySsntOGd22dfPf3vJ6RwsgInG_QgkCaiN4WAACG8OskmyVCVPZk2TN-mRxCDeMDbqCZ_zTPqKk1V-_p1Zwj7c0TkoYuqmQiP4gGOZEWLpfVdjr_qhfDHG-Wg=w2400"
                    alt=""
                />
            </div>
            <div class="py-5 lg:text-left">
                <h2
                    class="font-bold text-3xl mb-2"
                    :style="{ color: '#0D528D' }"
                >
                    Jadi partner GenBI IPB
                </h2>
                <p class="mb-3 mt-8 leading-8">
                    GenBI IPB selalu tertarik dengan peluang kolaborasi baik itu
                    untuk kegiatan sosial, pembicara, ataupun sponsor. Pelajari
                    lebih lanjut tentang apa yang dapat kita lakukan bersama
                    untuk mendukung tujuan bersama!
                </p>
                <button
                    @click="$router.push('/partner')"
                    class="mt-8 rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-10 text-white"
                >
                    Selengkapnya
                </button>
            </div>
        </div>
        <!-- <div class="py-10">
            <h2
                class="font-bold text-3xl mb-2 font-bold text-3xl mb-2 text-left"
                :style="{ color: '#0D528D' }"
            >
                Di GenBI IPB ngapain apa aja sih?
            </h2>
            <p class="text-left">
                Yuk, kulik aktifitas yang telah dilakukan GenBI IPB.
            </p>
            <div
                class="pb-10 grid lg:grid-cols-2 md:grid-cols-1 gap-10 items-center"
            >
                <div class="py-5 lg:text-left">
                    <div
                        class="rounded-lg bg-gray-100"
                        style="height: 250px"
                    ></div>
                    <h3
                        class="font-bold text-2xl mb-2"
                        :style="{ color: '#494949' }"
                    >
                        Lorem, ipsum dolor sit amet consectetur adipisicing.
                    </h3>
                    <small class="">Sat, 1 Januari 2021 - 3 mins read</small>
                    <small
                        >Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quod magnam odio molestiae culpa
                        possimus...</small
                    >
                </div>
                <div class="py-5 lg:text-left">
                    <div class="py-3" v-for="i in 3" :key="i">
                        <h3
                            class="font-bold text-2xl mb-2"
                            :style="{ color: '#494949' }"
                        >
                            Lorem, ipsum dolor sit amet consectetur adipisicing.
                        </h3>
                        <small class=""
                            >Sat, 1 Januari 2021 - 3 mins read</small
                        >
                        <hr class="py-2" />
                    </div>
                </div>
            </div>
            <button
                class="rounded-full px-5 py-2 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-10 text-blue-400 border-2 border-blue-500"
            >
                Lihat semua aktifitas
            </button>
        </div> 
    </div> -->
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {},
    metaInfo: {
        titleTemplate: `%s - Home`,
        htmlAttrs: {
            lang: 'id',
            amp: true,
        },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content:
                    'Program Beasiswa Bank Indonesia merupakan program beasiswa kepada mahasiswa/i berprestasi di seluruh Indonesia. Per November 2020, beasiswa Bank Indonesia sudah diberikan kepada tersebar 10.545 mahasiswa/i dari 175 Perguruan Tinggi Negeri dan Perguruan Tinggi Swasta di seluruh Indonesia.',
            },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
        ],
    },
}
</script>
<style>
.hero {
    height: 500px;
    background-repeat: no-repeat;
    min-width: 100%;
    background-size: cover;
}
#home-hero {
    background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.6)
        ),
        url('https://lh3.googleusercontent.com/SOxGS_sNmk9eb-ElJORzM1oHyPV4tkT_XylUBT3YKhjKGpzgf-etpQGsC8J8koM5pMrXTjvhVWgGrZOPhLXZwsvBn9LfSwNhWpNAxXbFIhpaXqHwvV4i6vimUEhJ7WkXVWSjW8xtmA=w2400');
}
</style>
