<template>
    <div class="container mx-auto">
        <nav
            class="flex items-center justify-between flex-wrap bg-teal-500 p-6"
        >
            <div class="flex items-center flex-shrink-0 mr-6">
                <router-link to="/" alt="logo">
                    <img src="../assets/logo-long.png" alt="Logo" />
                </router-link>
            </div>

            <div class="block lg:hidden">
                <button
                    class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-blue-700 hover:border-blue-700"
                    @click.stop="open_menu = !open_menu"
                >
                    <svg
                        class="fill-current h-3 w-3"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                </button>
            </div>
            <div
                class="w-full block flex-grow lg:flex sm:items-center lg:w-auto"
                :class="{ hidden: !open_menu }"
            >
                <div class="text-sm ml-auto">
                    <router-link
                        :to="item.route"
                        v-for="item in navItems"
                        :key="item.route"
                        class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-blue-900 lg:ml-6"
                    >
                        {{ item.text }}
                    </router-link>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data: function() {
        return {
            open_menu: false,
            navItems: [
                {
                    route: '/tentang',
                    text: 'Tentang GenBI IPB',
                },
                {
                    route: '/anggota',
                    text: 'Jadi Bagian GenBI',
                },
                {
                    route: '/partner',
                    text: 'Jadi Partner GenBI',
                },
                {
                    route: '/blog',
                    text: 'Blog',
                },
                {
                    route: '/kontak',
                    text: 'Kontak',
                },
                {
                    route: '/faq',
                    text: 'FAQ',
                },
            ],
        }
    },
}
</script>
