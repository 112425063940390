<template>
    <div class="container mx-auto bg-yellow-50 mb-16 rounded-lg">
        <div class="grid lg:grid-cols-3 md:grid-cols-1 gap-10 py-4">
            <div class="col-span-2 lg:text-left p-5">
                <img src="../assets/logo-long.png" alt="Logo" class="pb-5" />
                <p>“Energi Untuk Negeri”</p>
            </div>
            <div class="lg:text-left p-5">
                <div class="font-bold text-2xl pb-5">Contact Us</div>
                <div class="">
                    <small>Email: contact@ipbgenbi.id</small>
                    <br />
                    <small
                        >Alamat: Kampus IPB, Jl. Raya Dramaga, Babakan, Kec.
                        Dramaga, Kota Bogor, Jawa Barat 16680</small
                    >
                </div>
            </div>
        </div>
        <hr />
        <div class="grid lg:grid-cols-3 md:grid-cols-1 gap-10 py-1">
            <div class="col-span-2 lg:text-left p-5">
                <div class="font-bold">
                    <small> Copyright GenBI IPB. All Rights Reserved. </small>
                </div>
            </div>
            <div class="lg:text-left p-5">
                <div class="grid grid-cols-3">
                    <a href="https://www.instagram.com/genbi_ipb/"
                        ><img src="../assets/footer/ig.png" alt="Logo"
                    /></a>
                    <a href="https://www.linkedin.com/company/genbi-ipb/"
                        ><img src="../assets/footer/linkedin.png" alt="Logo"
                    /></a>
                    <a
                        href="https://www.youtube.com/channel/UCBaW7cQ9Fw-TOr4tpazWtJQ"
                        ><img src="../assets/footer/youtube.png" alt="Logo"
                    /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data: function() {
        return {}
    },
}
</script>
